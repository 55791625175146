import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import '@autopay.io/screen-style/lib/main.css';
import {Database, getDatabase, onDisconnect, serverTimestamp, onValue, ref, set} from 'firebase/database';
import {getApp, getApps, initializeApp} from 'firebase/app';
import {ConfigInfo, EventInfo} from "./types";
import {getDeviceId} from './utils';
import {Spinner} from '@autopay.io/style';
import {getFirebaseInstanceUrl} from '../../util/utils';
import {isScreenOnline, sendScreenEventLog} from "../../server";
import {browserHistory} from "../../app";
import moment = require('moment');
import {EntryExitScreen} from "@autopay.io/screen-style";
import {ScreenIndexProps} from "../../types";

let db : Database
fetch('/__/firebase/init.json')
    .then((response) => {
        return response.json()
    })
    .then((firebaseConfig) => {
        const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
        db = getDatabase(app, getFirebaseInstanceUrl);
    });

export const EntryExitIndex = ({screenId}: ScreenIndexProps) => {
    let dbConnected = useRef<boolean>(false)
    let hasConfig = useRef<boolean>(false)
    let event = useRef<EventInfo | null>(null); // for checking with ui version to refresh
    const deviceId = getDeviceId();
    let eventTimeout: NodeJS.Timeout;
    let uiVersion: string | null;
    const searchParams = new URLSearchParams(browserHistory.location.search);
    const redirectedFromUrl = searchParams.get('redirect')

    const [eventInfo, setEventInfo] = useState<EventInfo | null>(null);
    const [configInfo, setConfigInfo] = useState<ConfigInfo | null>(null);

    function addLog(data: EventInfo) {
        let date = new Date();
        let lastEventTimeRef = ref(db, `screens/${screenId}/devices/${deviceId}/lastEventTime`);

        set(lastEventTimeRef, date.toISOString())
        let lastEventIdRef = ref(db, `screens/${screenId}/devices/${deviceId}/lastEventId`);
        set(lastEventIdRef, data.eventId)

        sendScreenEventLog(data, deviceId, date.toISOString())
    }

    function refreshScreen() {
        setInterval(() => {
            if (event.current === undefined || event.current === null) {
                isScreenOnline().then((isOnline) => {
                    if (isOnline) {
                        window.location.href = location.pathname;
                    }
                })
            }
        }, 60000);
    }

    function updateEventTimeout(data: EventInfo) {
        event.current = data;
        if (eventTimeout) {
            clearTimeout(eventTimeout)
        }
        eventTimeout = setTimeout(() => {
            setEventInfo(null);
            event.current = null;
        }, 10000)
    }

    function receiveConfigUpdates() {
        let configRef = ref(db, `screens/${screenId}/config`);
        onValue(configRef, (snapshot) => {
            const data: ConfigInfo | null = snapshot.val();
            if (data) {
                setConfigInfo(data)
                hasConfig.current = true
            }
        });
    }

    function receiveEvents() {
        let eventRef = ref(db, `screens/${screenId}/event`);
        let initialLoad: boolean = true;
        onValue(eventRef, (snapshot) => {
            if (initialLoad) {
                initialLoad = false;
            } else {
                const data: EventInfo | null = snapshot.val();
                if (data) {
                    setEventInfo(data);
                    updateEventTimeout(data);
                    addLog(data);
                }
            }
        });
    }

    function handleOnlineStatus() {
        let connectRef = ref(db, `screens/${screenId}/devices/${deviceId}/online`);
        let lastSeenRef = ref(db, `screens/${screenId}/devices/${deviceId}/lastSeen`);

        // .info/connected holds information if client is connected or not
        const connectedRef = ref(db, ".info/connected");

        onValue(connectedRef, (snap) => {
                if (snap.val() === true) {
                    set(connectRef, true)

                    onDisconnect(connectRef).set(false)
                    onDisconnect(lastSeenRef).set(serverTimestamp())
                }
            }
        )
    }

    function receiveUiVersion() {
        let versionConfigRef = ref(db, `versions/ui-version`);
        onValue(versionConfigRef, (snapshot) => {
            const newUiVersion: string | null = snapshot.val();
            if (newUiVersion) {
                if (uiVersion && uiVersion !== newUiVersion) {
                    refreshScreen();
                } else {
                    uiVersion = newUiVersion;
                }
            }
        });
    }

    useEffect(() => {
        if (db) {
            dbConnected.current = true
            receiveConfigUpdates();
            receiveEvents();
            handleOnlineStatus();
            receiveUiVersion();
        } else {
            window.location.href = location.pathname;
        }
    }, [db]);


    useEffect(() => {
        setInterval(() => {
            const needsRedirect = !hasConfig.current || !dbConnected.current
            if (redirectedFromUrl && needsRedirect) {
                let errorMessage = ""
                if (!hasConfig.current) {
                    errorMessage = 'Failed to get config from database'
                }
                if (!dbConnected.current) {
                    errorMessage = 'Failed to connect to database'
                }
                const message =  `&error=${encodeURIComponent(errorMessage)}`
                isScreenOnline().then((isOnline) => {
                    if (isOnline) {
                        window.location.href = decodeURI(redirectedFromUrl) + message
                    }
                })
            }
        }, 60000)
    },[])

    useEffect(() => {
        const now = moment();
        const refreshTime = moment().add(1, 'day').set({hour: 3, minute: 0, second: 0, millisecond: 0})
        const msToRefresh = refreshTime.diff(now, 'ms');

        setTimeout(() => {
            refreshScreen();
        }, msToRefresh)
    }, []);

    if (!!configInfo) {
        return (
            <EntryExitScreen
                configInfo={configInfo}
                eventInfo={eventInfo}
            />
        );
    }

    return <Spinner size="lg" />;
};
