import * as React from 'react';
import {useEffect, useState} from 'react';
import {getScreenInformation} from '../server';
import {ScreenInfoState} from '../types';
import '@autopay.io/screen-style/lib/main.css';
import {getScreenType} from "../../server";
import {PriceScreenIndex} from "../price";
import {ZonesScreenIndex} from "../zones";
import {MultiScreen} from '@autopay.io/screen-style';
import {Spinner} from "@autopay.io/style";
import {ErrorScreen} from "../../common/components/ErrorScreen";
import {ScreenIndexProps} from "../../types";

interface Screen {
    id: string;
    component: JSX.Element;
}

export const MultiScreenIndex = ({screenId}: ScreenIndexProps) => {
    const [activeScreenIndex, setActiveScreenIndex] = useState<number>(0);

    const [screenInfo, setScreenInfo] = useState<ScreenInfoState>(null);
    const [screens, setScreens] = useState<Map<string, Screen | undefined>>(new Map());
    const [screenInterval, setScreenInterval] = useState<number>(5000)

    useEffect(() => {
        getInfo(screenId)
    }, [screenId]);

    useEffect(() => {
        if (screenInfo?.type === 'DATA' && screenInfo.screen.screenInterval) {
            setScreenInterval(() => {
                switch (screenInfo.screen.screenInterval?.unit) {
                    case 'MINUTE': case 'MINUTES': return screenInfo.screen.screenInterval?.amount * 60 * 1000
                    case 'SECOND': case 'SECONDS': return screenInfo.screen.screenInterval?.amount * 1000
                    default: return 5000
                }
            })

            setScreens(new Map<string, undefined>(screenInfo.screen.screenIds!!.map(key => [key, undefined])))

            screenInfo.screen.screenIds!!.map((screenId, index) => {
                getScreenType(screenId).then((response) => {
                    if (response !== null && response.type === 'DATA') {
                        switch (response.data.type) {
                            case 'PRICE': return addScreenEntry(screenId, {
                                id: screenId,
                                component: <PriceScreenIndex screenId={screenId} carouselLoaderConfig={{
                                    screenIndex: index,
                                    totalItems: screenInfo.screen.screenIds!!.length
                                }}/>

                            })
                            case 'ZONES': return addScreenEntry(screenId, {
                                id: screenId,
                                component: <ZonesScreenIndex screenType={'ZONES'} screenId={screenId} carouselLoaderConfig={{
                                    screenIndex: index,
                                    totalItems: screenInfo.screen.screenIds!!.length
                                }}/>
                            })
                        }
                    }
                });
            })
        }
    }, [screenInfo]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            nextScreen();
        }, screenInterval);

        return () => clearTimeout(timeout);
    }, [screenId, screens, activeScreenIndex]);

    const getInfo = (id: string) => {
        getScreenInformation(id).then((r) => {
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setScreenInfo({type: 'DATA', screen: r})
            } else if (r === 'NOT_FOUND') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Screen not found'});
            } else if (r === 'ERROR') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Data not found'});
            }
        });

    };

    const addScreenEntry = (key: string, value: Screen) => {
        setScreens((prevMap) => new Map(prevMap).set(key, value));
    };

    const nextScreen = () => {
        const nextIndex = (activeScreenIndex + 1) % screens.size;
        setActiveScreenIndex(nextIndex);
    };

    if (screenInfo === null) {
        return <Spinner size={'md'}/>;
    } else if (screenInfo.type === 'ERROR') {
        return <ErrorScreen text={screenInfo.message}/>;
    } else {
        return <MultiScreen screens={screens} activeIndex={activeScreenIndex}/>
    }

};
