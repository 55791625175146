import * as React from 'react';
import {useEffect, useState} from 'react';
import {getScreenInformation, getScreenOccupancies} from '../server';
import {CarouselLoaderConfig, ScreenInfoState, ZoneOccupancy} from '../types';
import {Spinner} from '@autopay.io/style';
import {ErrorScreen} from "../../common/components/ErrorScreen";
import {
    dataFetchIntervalInMs,
    LAST_SUCCESSFUL_DATA_FETCH,
    occupancyFetchIntervalInMs,
    twoHoursInMs
} from "../constants";
import '@autopay.io/screen-style/lib/main.css';
import {runEveryMidnight, runTenSecondsAfterEveryFullHour} from "../../util/utils";
import {ZonesScreen} from '@autopay.io/screen-style';
import {ScreenIndexProps, ScreenType} from "../../types";
import {TenantOccupancy} from "@autopay.io/screen-style/lib/screen/types";

interface ZonesScreenIndexProps extends ScreenIndexProps {
    screenType: ScreenType;
    carouselLoaderConfig?: CarouselLoaderConfig;
}

export const ZonesScreenIndex = ({screenId, screenType, carouselLoaderConfig}: ZonesScreenIndexProps) => {

    const [screenInfo, setScreenInfo] = useState<ScreenInfoState>(null);
    const [zoneOccupancies, setZoneOccupancies] = useState<ZoneOccupancy[]>([]);
    const [tenantOccupancies, setTenantOccupancies] = useState<TenantOccupancy[]>([]);

    useEffect(() => {
        getScreenInfo(screenId)
        setInterval(() => getScreenInfo(screenId), dataFetchIntervalInMs)
        runTenSecondsAfterEveryFullHour(() => getScreenInfo(screenId))
        runEveryMidnight(() => location.reload())
    }, [screenId]);

    useEffect(() => {
        if (screenInfo?.type === 'DATA' && (screenInfo.screen.zonesDisplayContentType === 'OCCUPANCY' || screenType === 'TENANT_OCCUPANCY')) {
            getOccupancyInfo(screenId)
            const occupancyTimer = setInterval(() => getOccupancyInfo(screenId), occupancyFetchIntervalInMs)
            return () => clearInterval(occupancyTimer);
        }
    }, [screenInfo]);

    const getScreenInfo = (id: string) => {
        getScreenInformation(id).then((r) => {
            const lastSuccessfulDataFetch = localStorage.getItem(LAST_SUCCESSFUL_DATA_FETCH);
            const now = new Date().getTime();
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setScreenInfo({type: 'DATA', screen: r});
                localStorage.setItem(LAST_SUCCESSFUL_DATA_FETCH, Date.now().toString());
            } else if (r === 'NOT_FOUND') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Screen not found'});
            } else if (r === 'ERROR' && lastSuccessfulDataFetch !== null && now - Number(lastSuccessfulDataFetch) > twoHoursInMs) {
                setScreenInfo({type: 'ERROR', code: null, message: 'Data not found'});
            }
        });
    }

    const getOccupancyInfo = (id: string) => {
        getScreenOccupancies(id).then((r) => {
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                if (screenInfo?.type === 'DATA' && screenType === 'TENANT_OCCUPANCY') {
                    setTenantOccupancies(r as TenantOccupancy[]);
                } else {
                    setZoneOccupancies(r as ZoneOccupancy[]);
                }
            }
        })
    }

    const shouldShowSpinner = (): boolean => {
        return screenInfo === null
            || (screenInfo.type === 'DATA' && screenInfo.screen.zonesDisplayContentType === 'OCCUPANCY' && zoneOccupancies.length === 0)
            || (screenInfo.type === 'DATA' && screenType === 'TENANT_OCCUPANCY' && tenantOccupancies.length === 0)
    }

    if (shouldShowSpinner()) {
        return <Spinner size={'md'} />;
    } else if (screenInfo!!.type === 'ERROR') {
        return <ErrorScreen text={screenInfo!!.message}/>;
    } else {
        return <ZonesScreen
            screenInfo={screenInfo!!.screen}
            tenantOccupancies={tenantOccupancies}
            zoneOccupancies={zoneOccupancies}
            carouselLoaderConfig={carouselLoaderConfig}
        />
    }
};
